// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-about-about-js": () => import("./../src/layouts/About/About.js" /* webpackChunkName: "component---src-layouts-about-about-js" */),
  "component---src-layouts-article-article-js": () => import("./../src/layouts/Article/Article.js" /* webpackChunkName: "component---src-layouts-article-article-js" */),
  "component---src-layouts-homepage-homepage-js": () => import("./../src/layouts/Homepage/Homepage.js" /* webpackChunkName: "component---src-layouts-homepage-homepage-js" */),
  "component---src-layouts-legal-legal-js": () => import("./../src/layouts/Legal/Legal.js" /* webpackChunkName: "component---src-layouts-legal-legal-js" */),
  "component---src-layouts-management-system-management-system-js": () => import("./../src/layouts/ManagementSystem/ManagementSystem.js" /* webpackChunkName: "component---src-layouts-management-system-management-system-js" */),
  "component---src-layouts-news-news-js": () => import("./../src/layouts/News/News.js" /* webpackChunkName: "component---src-layouts-news-news-js" */),
  "component---src-layouts-product-product-js": () => import("./../src/layouts/Product/Product.js" /* webpackChunkName: "component---src-layouts-product-product-js" */),
  "component---src-layouts-product-aggregator-product-aggregator-js": () => import("./../src/layouts/ProductAggregator/ProductAggregator.js" /* webpackChunkName: "component---src-layouts-product-aggregator-product-aggregator-js" */),
  "component---src-layouts-services-services-js": () => import("./../src/layouts/Services/Services.js" /* webpackChunkName: "component---src-layouts-services-services-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

